/*Contact Item Css Start here*/

.contact-item {
    @extend %flex;
    width: 100%;
    padding: 30px 25px;
    margin-bottom: 25px;
    background: $white-color;
    box-shadow: 0 5px 5px rgba(136, 136, 136, 0.05);
    @include transition($transition);

    &:hover {
        box-shadow: 0 5px 5px rgba(136, 136, 136, 0.12);
    }

    .contact-icon {
        width: 72px;

        i {
            height: 50px;
            width: 50px;
            line-height: 50px;
            text-align: center;
            border-radius: 100%;
            text-decoration: none;
            display: inline-block;
            color: $white-color;
            font-size: 24px;
            background: #f85b82;
        }
    }

    .contact-details {
        width: calc(100% - 72px);

        p {
            margin: 0;
        }
    }
}

.col-12 {
    &:nth-child(2) {
        .contact-item {
            .contact-icon i {
                background: $theme-color;
            }
        }
    }

    &:nth-child(3) {
        .contact-item {
            .contact-icon i {
                background: #5ce1b9;
            }
        }
    }
}

/*Contact Item Css end here*/

/* @@@@@@@@@@@@@  Contact Us Section Start Here  @@@@@@@@@@@@@@ */

.location-map {
    @include breakpoint(max-lg) {
        margin-bottom: 15px;
    }

    #map {
        height: 435px;
        width: 100%;

        iframe {
            height: 100%;
            width: 100%;
            border: none;
        }
    }
}

.contact-form-wrapper {
    @extend %bg-primary;
    @extend %p-30;
    @extend %border-radius-2;

    .contact-form {
        p {
            max-width: 610px;
            &.form-message {
                max-width: 100%;
                &.error, &.success {
                    margin-top: 15px;
                }
                &.error {
                    color: $theme-color;
                }
            }
        }

        h5 {
            font-family: $font;
        }

        form {
            &.comment-form {
                @extend %flex;
                @extend %justify-between;
                @extend %w-100;
                @extend %mt-0;

                input[type="text"],
                [type="email"] {
                    @extend %p-15;
                    background: rgba($white-color, $alpha: .1);
                    @extend %w-100;
                    @extend %mb-30;
                    @extend %border-radius-2;
                    border: none;
                    border: 1px solid transparent;

                    &:focus-visible {
                        border: 1px solid rgba($white-color, $alpha: .1);
                        outline: none;
                    }

                    @include breakpoint(sm) {
                        width: calc(100% / 2 - 10px);
                    }
                }

                textarea {
                    background: rgba($white-color, $alpha: .1);
                    @extend %w-100;
                    @extend %border-radius-2;
                    padding: 10px 15px;
                    border: 1px solid transparent;

                    &:focus-visible {
                        border: 1px solid rgba($white-color, $alpha: .1);
                        outline: none;
                    }
                }

                button {
                    &.lab-btn {
                        @extend %mt-30;
                        background: $theme-color;
                        outline: none;
                        border: none;
                        @include border-radius(3px);
                        box-shadow: 0 5px 5px rgba($theme-color, $alpha: .30);
                        @extend %pointer;

                        &:hover {
                            box-shadow: none;
                            transform: translateY(-3px);
                        }
                    }
                }
            }
        }
    }
}

.contact-info-wrapper {

    @extend %bg-primary;
    @extend %p-30;
    @extend %border-radius-2;

    @include breakpoint(max-lg) {
        margin-top: 50px;
    }

    .contact-info-title {
        h5 {
            font-family: $font;
        }

        p {
            max-width: 280px;
            margin-bottom: 30px;
        }
    }

    .contact-info-content {
        @include breakpoint(max-lg) {
            display: flex;
            flex-wrap: wrap;
        }

        .contact-info-item {
            @include breakpoint(max-lg) {
                margin-right: 30px;
                margin-bottom: 30px;
            }

            @include breakpoint (lg) {
                margin-bottom: 30px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .contact-info-inner {
                @include breakpoint(max-lg) {
                    display: flex;
                    // flex-wrap: wrap;
                    // align-items: center;

                    .contact-info-thumb {
                        margin-bottom: 0;
                        // margin-right: 15px;
                    }
                }

                .contact-info-thumb {
                    margin-bottom: 17px;
                }

                .contact-info-details {
                    @include breakpoint(max-lg) {
                        padding-left: 15px;
                    }
                    span {
                        color: $title-color;
                    }
                }
            }
        }
    }

}